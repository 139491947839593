// import axios from "axios";

// export async function GetIp(params) {
//   var ip = "";
//   var country = "";
//   try {
//     await axios
//       .post(
//         "https://geolocation-db.com/json/"
//         // "https://ws.geonames.org/countryCode",
//         // {
//         //   lat: position.coords.latitude,
//         //   lng: position.coords.longitude,
//         //   type: "JSON",
//         // }
//         // "https://ipinfo.io/139.135.52.134?token=ecb288dfa738da"
//       )

//       .then(async (res) => {
//         console.log("res of 1st call", res);

//         ip = res.data.IPv4;
//         country = res.data.country;
//         // await axios
//         //   .post(`https://ipinfo.io/${ip}?token=ecb288dfa738da`)

//         //   .then((res2) => {
//         //     console.log("res of 2st call", res2);

//         //     // ip = res.data.IPv4;
//         //     const data = res2.data;
//         //     country =
//         //       data.country === "PK"
//         //         ? "Pakistan"
//         //         : data.country === "US" || data.country === "USA"
//         //         ? "United States"
//         //         : "";
//         //     return { ip: ip, country: country };
//         //   })
//         //   .catch(function (error) {
//         //     ip = "no data";
//         //     country = "no data";
//         //     console.log(error);
//         //     return { ip, country };
//         //   });
//         // country = res.data.country_name;
//       })
//       .catch(function (error) {
//         ip = "no data";
//         country = "no data";
//         console.log(error);
//       });
//   } catch {
//     console.log("catch");
//     ip = "no Data";
//     country = "no Data";
//   }
//   return { ip, country };
// }
// import axios from "axios";

export async function GetIp(params) {
  // let ip = "";
  // let country = "";

  // const timeout = (ms) =>
  //   new Promise((resolve) => setTimeout(resolve, ms, null));

  // const geoLocationPromise = axios
  //   .get("https://geolocation-db.com/json/")
  //   .then((res) => {
  //     ip = res.data.IPv4;
  //     country = res.data.country_name;
  //     return { ip, country };
  //   })
  //   .catch((error) => {
  //     ip = "no data";
  //     country = "no data";
  //     console.log(error);
  //     return { ip, country };
  //   });
  return { ip: "1111111", country: "test" };
  // const geoLocationPromise = axios
  //   .get("http://ip-api.com/json")
  //   .then((res) => {
  //     ip = res.data.query;
  //     country = res.data.country;
  //     return { ip, country };
  //   })
  //   .catch((error) => {
  //     ip = "no data";
  //     country = "no data";
  //     console.log(error);
  //     return { ip, country };
  //   });

  //   const result = await Promise.race([
  //     geoLocationPromise,
  //     timeout(3000), // Set a reasonable timeout
  //   ]);

  //   if (result === null) {
  //     return { ip: null, country: null };
  //   }

  //   return result;
}
